import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./component/Layout";
import { useCookies } from "react-cookie";
import i18n from "./i18n";

const ChooseYourSetting = lazy(() =>
  import("./component/ApplicationPreview/ChooseYourSetting")
);
const SelectYourDiamond = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond")
);
const CompleteYourRing = lazy(() =>
  import("./component/ApplicationPreview/CompleteYourRing")
);
const ChooseSettingItemDetails = lazy(() =>
  import("./component/ApplicationPreview/ChooseSettingItemDetails")
);
const MinedDiamond = lazy(() =>
  import("./component/common/SelectYourDiamond/MinedDiamond")
);
const LabGrown = lazy(() =>
  import("./component/common/SelectYourDiamond/LabGrown")
);
const Compare = lazy(() =>
  import("./component/common/SelectYourDiamond/Compare")
);
const Request = lazy(() =>
  import("./component/common/SelectYourDiamond/Request")
);
const ViewDiamondDetails = lazy(() =>
  import("./component/common/SelectYourDiamond/ViewDiamondDetails")
);

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const [cookies] = useCookies(["lngCookie"]);

  const language = cookies.lngCookie;

  useEffect(() => {
    if (language) {
      //// check langauge name for conditions
      let tempLanguage =
        language === "English" || language === "en"
          ? "en"
          : language === "Spanish" || language === "es"
          ? "es"
          : language === "Arabic" || language === "ar"
          ? "ar"
          : "en";
      i18n.changeLanguage(tempLanguage);
    }
  }, [language, i18n]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--dynamic-font-family",
      fontStyle || "LexendDeca-Regular"
    );
  }, [fontStyle]);

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <Suspense fallback={""}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                path="/SelectYourDiamond"
                element={<SelectYourDiamond />}
              />
              <Route path="/" element={<ChooseYourSetting />} />
              <Route
                path="/ChooseSettingItemDetails"
                element={<ChooseSettingItemDetails />}
              />
              <Route
                path="/viewDiamondDetails"
                element={<ViewDiamondDetails />}
              />

              <Route path="/CompleteYourRing" element={<CompleteYourRing />} />

              <Route path="/Mined" element={<MinedDiamond />} />
              <Route path="/Lab" element={<LabGrown />} />
              <Route path="/Request" element={<Request />} />
              <Route path="/Compare" element={<Compare />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
