import {
  SET_FONT_FAMILY,
  SET_PREVIEW_COLOR,
  SWITCH_TOGGLE,
} from "../../actions/PreviewColor/types";

const initialState = {
  buttonColor: {
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  },

  toogle: true,
  fontFamily: "",
};

export default function handleChooseYourSetting(
  previewcolor = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_PREVIEW_COLOR:
      return {
        ...previewcolor,
        buttonColor: {
          callToActionColor: payload.actionButton,
          hoverColor: payload.hoverEffect,
          columnHeaderAccentColor: payload.columnHeaderAccent,
          linkColor: payload.linkColor,
        },
      };
    case SWITCH_TOGGLE:
      return {
        ...previewcolor,
        toogle: !previewcolor.toogle,
      };

    case SET_FONT_FAMILY:
      return {
        ...previewcolor,
        fontFamily: payload,
      };
    default:
      return previewcolor;
  }
}
